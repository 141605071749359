<template>
  <transition name="fade">
    <div v-if="show" class="pop_back" @click="$emit('close')">
      <div class="pop_alert" @click="$event.stopPropagation()">
        <a href="javascript:" @click="$emit('close')" class="close">
          <img src="../../assets/img/btn_close.png">
        </a>
        <div class="title"><p>{{ $t('story_purchase.title') }}</p></div>
        <div style="display:flex;flex-direction: row;align-items: center;z-index:100;justify-content: center;margin-top: 10px;">
          <img src="../../assets/img/flowers.png" style="width:30px;height:30px;">
          <span style="font-size: 25px;color:black;margin-left: 4px;margin-top:8px;">{{ photoInfo.price }}</span>
        </div>
        <div class="message">
          {{ $t('story_purchase.description1') }}
          <br/>
          {{ $t('story_purchase.description2') }}
        </div>
        <a href="javascript:" class="btn_yes" @click="$emit('ok')">
          {{ $t('common.yes') }}
        </a>
        <a href="javascript:" class="btn_not" @click="$emit('close')">
          {{ $t('common.no') }}
        </a>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped src="./StoryPurchaseConfirm.scss"></style>

<script>
export default {
  name: "StoryPurchaseConfirm",
  props: {
    photoInfo: {
      type: Object,
      required: true,
      default() {
        return {};
      }
    },
    show: {
      type: Boolean,
      default: false
    },
  },
  emits: ['close', 'ok'],
  data() {
    return {
      backButtonRouteGuard: null,
    }
  },
  watch: {
    show(show) {
      if (show) {
        // dialog show
        document.addEventListener('keyup', this.onKeyPress);
        // back button
        this.backButtonRouteGuard = this.$router.beforeEach((to, from, next) => {
          this.$emit('close');
          next(false);
        });
      } else {
        // dialog hide
        document.removeEventListener('keyup', this.onKeyPress);
        // back button
        if (this.backButtonRouteGuard) {
          this.backButtonRouteGuard();
        }
      }
    }
  },
  methods: {
    onKeyPress: function (e) {
      if (e.keyCode == 27) {
        let me = document.querySelector('.pop_alert').parentElement;
        let activeDlgList = document.querySelectorAll('.pop_back');

        if (activeDlgList.item(activeDlgList.length - 1) == me) {
          this.$emit('close');
        }
      }
    },
  }
}
</script>
