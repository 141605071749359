<template>
  <transition name="fade">
    <div v-if="show" class="pop_back" @click="$emit('close')">
      <div class="pop_photo_price_guideline" @click="$event.stopPropagation()">
        <a href="javascript:" @click="$emit('close')" class="close">
          <img src="../../../assets/img/btn_close.png">
        </a>
        <div class="title">{{ $t('photo.price_guideline') }}</div>
        <div class="text_lines">
          <div class="text_line">
            <span class="text1">{{ $t('photo.price_guideline_photo_1') }}&nbsp;</span>
            <span class="text2">{{ $t('photo.price_guideline_photo_2') }}</span>
          </div>
          <div class="text_line">
            <span class="text1">{{ $t('photo.price_guideline_video_1') }}&nbsp;</span>
            <span class="text2">{{ $t('photo.price_guideline_video_2') }}</span>
          </div>
          <div class="text_line">
            <span class="text1">{{ $t('photo.price_guideline_audio_1') }}&nbsp;</span>
            <span class="text2">{{ $t('photo.price_guideline_audio_2') }}</span>
          </div>
        </div>
        <a href="javascript:" class="btn_not" @click="$emit('close')">
          {{ $t('common.close') }}
        </a>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped src="./PriceGuidelinePopup.scss"></style>

<script>
export default {
  name: "PriceGuidelinePopup",
  props: {
    show: {
      type: Boolean,
      default: false
    },
  },
  emits: ['close'],
  data() {
    return {
      backButtonRouteGuard: null,
    }
  },
  watch: {
    show(show) {
      if (show) {
        // dialog show
        document.addEventListener('keyup', this.onKeyPress);
        // back button
        this.backButtonRouteGuard = this.$router.beforeEach((to, from, next) => {
          this.$emit('close');
          next(false);
        });
      } else {
        // dialog hide
        document.removeEventListener('keyup', this.onKeyPress);
        // back button
        if (this.backButtonRouteGuard) {
          this.backButtonRouteGuard();
        }
      }
    }
  },
  methods: {
    onKeyPress: function (e) {
      if (e.keyCode == 27) {
        let me = document.querySelector('.pop_photo_price_guideline').parentElement;
        let activeDlgList = document.querySelectorAll('.pop_back');

        if (activeDlgList.item(activeDlgList.length - 1) == me) {
          this.$emit('close');
        }
      }
    },
  }
}
</script>
