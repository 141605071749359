<template>
  <div style="width:100%;height:100%;">
    <!-- 타인 맞팔 공개 무료(맞팔아님): 블러, 중앙 맞팔태그 현시. -->
    <div v-if="!isMyMoment && publicFlag == 1 && isFollowing == 0" class="container">
      <div class="blur">
        <img src="../assets/img/blur.jpg">
      </div>
      <div>
        <img v-if="publicFlag == 1" src="../assets/img/story_item_lock.png">
        <div v-if="publicFlag == 0">
          <img src="../assets/img/flowers.png" style="width:25px;height:25px;">
          <span style="font-size: 20px;color:white;margin-left: 4px;margin-top:4px;">{{ price }}</span>
        </div>
        {{ $t('photo.public_to_followers') }}
      </div>
    </div>

    <!-- 타인 맞팔 공개 유료(맞팔아님): 블러, 중앙 맞팔태그, 가격 현시. -->
    <div v-if="!isMyMoment && publicFlag == 2 && isFollowing == 0" class="container">
      <div class="blur">
        <img src="../assets/img/blur.jpg">
      </div>
      <div>
        <img v-if="isPurchased == 0" src="../assets/img/story_item_lock.png">
        <div v-if="isPurchased == 0">
          <img v-if="publicFlag == 1" src="../assets/img/story_item_lock.png">
          <div v-if="publicFlag == 0">
            <img src="../assets/img/flowers.png">
            {{ price }}
          </div>
          {{ $t('photo.public_to_followers') }}
        </div>
        <div v-if="isPurchased == 1" style="position: absolute;left:10px;top:10px;display:flex;flex-direction: column;">
          <div style="display:flex;flex-direction: row;align-items: center;">
            <img src="../assets/img/story_item_public_to_follower.png" style="width:20px;height:20px;">
            <span style="font-size: 20px;color:white;margin-left: 4px;">{{ $t('photo.public_to_followers') }}</span>
          </div>
          <div style="display:flex;flex-direction: row;align-items: center;">
            <img src="../assets/img/flowers.png" style="width:25px;height:25px;">
            <span style="font-size: 20px;color:white;margin-left: 4px;margin-top:4px;">{{ price }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- 타인 맞팔 공개 무료(맞팔): 블러없음, 상단좌측 맞팔태그 현시. -->
    <div v-if="!isMyMoment && publicFlag == 1 && isFollowing == 1" class="container">
      <div v-if="isPurchased == 1" style="position: absolute;left:10px;top:10px;display:flex;flex-direction: row;">
        <div style="display:flex;flex-direction: row;align-items: center;">
          <img src="../assets/img/story_item_public_to_follower.png" style="width:20px;height:20px;">
          <span style="font-size: 20px;color:white;margin-left: 4px;">{{ $t('photo.public_to_followers') }}</span>
        </div>
      </div>
    </div>

    <!-- 타인 맞팔 공개 유료(맞팔, 결제안함): 블러, 중앙 맞팔태그, 가격 현시. -->
    <div v-if="!isMyMoment &&
      publicFlag == 2 &&
      isFollowing == 1 &&
      isPurchased == 0" class="container">
      <div class="blur">
        <img src="../assets/img/blur.jpg">
      </div>
      <div style="display:flex;flex-direction: column;align-items: center;">
        <div style="display:flex;flex-direction: row;align-items: center;z-index:100;">
          <img src="../assets/img/flowers.png" style="width:30px;height:30px;">
          <span style="font-size: 25px;color:white;margin-left: 4px;margin-top:8px;">{{ price }}</span>
        </div>
        <a href="javascript:" class="buy" @click.stop.prevent="onBuy()">{{ $t('charge.charge_purchase') }}</a>
      </div>
    </div>

    <!-- 타인 맞팔 공개 유료(맞팔, 결제안함): 블러, 중앙 맞팔태그, 가격 현시. -->
    <div v-if="!isMyMoment &&
      publicFlag == 2 &&
      isFollowing == 1 &&
      isPurchased == 1" class="container">
      <div v-if="isPurchased == 1" style="position: absolute;left:10px;top:10px;display:flex;flex-direction: column;align-items: flex-start;">
        <div class="tag_bg">
          <img src="../assets/img/story_item_public_to_follower.png" class="tag_icon">
          <span class="tag_text">{{ $t('photo.public_to_followers') }}</span>
        </div>
        <div class="tag_bg" style="margin-top: 4px;">
          <img src="../assets/img/flowers.png" class="tag_icon">
          <span class="tag_text">{{ price }}</span>
        </div>
      </div>
    </div>

    <!-- 나의 맞팔 공개 무료: 블러없음, 상단좌측 맞팔태그 현시. -->
    <div v-if="isMyMoment && publicFlag == 1" class="container">
      <div style="position: absolute;left:10px;top:10px;display:flex;flex-direction: row;">
        <div class="tag_bg">
          <img src="../assets/img/story_item_public_to_follower.png" class="tag_icon">
          <span class="tag_text">{{ $t('photo.public_to_followers') }}</span>
        </div>
      </div>
    </div>

    <!-- 나의 맞팔 공개 유료. 블러없음, 상단좌측 맞팔태그, 가격 현시. -->
    <div v-if="isMyMoment && publicFlag == 2" class="container">
      <div style="position: absolute;left:10px;top:10px;display:flex;flex-direction: column;align-items: flex-start;">
        <div class="tag_bg">
          <img src="../assets/img/story_item_public_to_follower.png" class="tag_icon">
          <span class="tag_text">{{ $t('photo.public_to_followers') }}</span>
        </div>
        <div class="tag_bg" style="margin-top: 4px;">
          <img src="../assets/img/flowers.png" class="tag_icon">
          <span class="tag_text">{{ price }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "StoryItemPublicFlagTagView",
  props: {
    isMyMoment: Boolean,
    publicFlag: Number,
    price: Number,
    isFollowing: Boolean,
    isPurchased: Boolean,
  },
  emits: ['onBuy'],
  methods: {
    onBuy: function() {
      this.$emit('onBuy');
    }
  }
}
</script>

<style>
.container {
  width:100%;height:100%;display:flex;align-items: center;justify-content: center;
}

.blur {
  position: absolute;
  width: 100%;
  top: 0px;
  bottom: 0px;
  background-color: antiquewhite;
  z-index: 0;
}

.blur img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.buy {
  padding: 10px 10px;
  margin-top:10px;
  border-radius: 10px;
  background-color: #f25c62;
  color:white;
  z-index: 10;
}

.tag_bg {
  display:flex;flex-direction: row;align-items: center;padding: 2px 10px; border-radius: 20px;background-color: #00000080;
}

.tag_icon {
  width:14px;height:14px;
}

.tag_text {
  font-size: 14px;color:white;margin-left: 4px;
}
</style>