<template>
  <transition name="fade">
    <div v-if="show" class="pop_back" @click="$emit('close')">
      <div class="pop_create" @click="$event.stopPropagation()">
        <div class="create_top">
          <h1>{{ $t('photo.write_title') }}</h1>
          <p>{{ $t('photo.write_desc') }}</p>
          <a href="javascript:" @click="$emit('close')">
            <img src="../../../assets/img/btn_close.png">
          </a>
        </div>
        <div class="create_box">
          <div class="photo" @click="onPick">
            <img v-if="imageDatas.length === 0" src="../../../assets/img/ico_photo.png" class="upload-icon" />
            <carousel  v-else paginationPosition="top">
              <slide v-for="(image, index) in imageDatas" :key="index">
                <img :src="image" class="upload-image" />
              </slide>
              
              <template #addons>
                <div style="{position:absolute;top:120px;}">
                <pagination />
              </div>
              </template>
            </carousel>
            <p v-if="imageDatas.length === 0">{{ $t('photo.pick_photo') }}</p>
          </div>
        </div>
        <div class="create_btn" @click="onNext">
          <a href="javascript:">{{ $t('common.next') }}</a>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped src="./CreateMomentStep.scss"></style>

<script>
import { showToast } from "../../../lib/util";
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination } from 'vue3-carousel'

export default {
  name: "CreateMomentStep1",
  data() {
    return {
      myInfo: this.$store.getters.me,
      images: [],
      imageDatas: [],
      content: '',
      backButtonRouteGuard: null,
    }
  },
  components: {
    Carousel,
    Slide,
    Pagination,
  },
  props: ['show'],
  emits: ['close', 'next'],
  watch: {
    show(show) {
      if (show) {
        this.myInfo = this.$store.getters.me;
        this.images = [];
        this.imageDatas = [];
        this.content = '';
        document.addEventListener('keyup', this.onKeyPress);
        // back button
        this.backButtonRouteGuard = this.$router.beforeEach((to, from, next) => {
          this.$emit('close');
          next(false);
        });
      } else {
        // dialog hide
        document.removeEventListener('keyup', this.onKeyPress);
        // back button
        if (this.backButtonRouteGuard) {
          this.backButtonRouteGuard();
        }
      }
    }
  },
  methods: {
    onKeyPress: function (e) {
      if (e.keyCode == 27) {
        let me = document.querySelector('.pop_create').parentElement;
        let activeDlgList = document.querySelectorAll('.pop_back');

        if (activeDlgList.item(activeDlgList.length - 1) == me) {
          this.$emit('close');
        }
      }
    },
    onPick() {
      let _app = this;
      this.$root.takePhotos(imgs => {
        if (imgs) {
          _app.images = imgs;
          for (let i = 0; i < imgs.length; i++) {
            let fr = new FileReader();
            fr.onload = function () {
              _app.imageDatas[i] = fr.result;
            };
            fr.readAsDataURL(imgs[i]);
          }
        } else {
          _app.images = [];
          _app.imageDatas = [];
        }
      });
    },
    onNext: async function () {
      if (this.imageDatas.length === 0) {
        showToast(this.$t('msg.please_select_photo'));
        return;
      }

      this.$emit('next', this.images, this.imageDatas);
    }
  }
}

</script>
<style>
  :root {

  /* Pagination */
  --vc-pgn-width: 6px;
  --vc-pgn-height: 6px;
  --vc-pgn-margin: 4px;
  --vc-pgn-border-radius: 0;
  --vc-pgn-background-color: #999;
  --vc-pgn-active-color: #e24c4d;
}
</style>