<template>
  <transition name="fade">
    <div v-if="show" class="pop_back" @click="$emit('close')">
      <div class="pop_create" @click="$event.stopPropagation()">
        <div class="create_top">
          <h1>{{ $t('photo.write_title') }}</h1>
          <p>{{ $t('photo.write_desc') }}</p>
          <a href="javascript:" @click="$emit('close')">
            <img src="../../../assets/img/btn_close.png">
          </a>
        </div>
        <div class="create_box2">
          <div class="left">
            <carousel paginationPosition="top">
              <slide v-for="(image, index) in imageDatas" :key="index">
                <img :src="image" class="thumbnail_image" />
              </slide>

              <template #addons>
                <div style="{position:absolute;top:120px;}">
                  <pagination />
                </div>
              </template>
            </carousel>
          </div>
          <div class="right">
            <div class="label">{{ $t('photo.content_hashtag') }}</div>
            <textarea v-model="content" :placeholder="$t('msg.input_photo_content')"></textarea>
            <div class="hashtag_select_btn"><a href="javascript:" @click="onHashtagSelect">{{ $t('photo.hashtag') }}</a>
            </div>
            <div style="font-size: 12px;color:#999">{{ $t('photo.hashtag_hint') }}</div>

            <div class="hashtags">
              <ul class="hashtag_list">
                <li v-for="hashtag in selectedHashtags" class="hashtag_item selected">
                  <a href="javascript:">#{{ hashtag }}</a>
                </li>
              </ul>
            </div>
            <div class="label">{{ $t('photo.public_options') }}</div>
            <ul class="public_options">
              <li class="public_option_radio"><a href="javascript:" @click="onPublicOptionClick(0)">
                  <img
                    :src="require(`../../../assets/img/story_create_check_${publicOptions == 0 ? 'on' : 'off'}.png`)" />
                  {{ $t('photo.free') }}
                </a></li>
              <li class="public_option_radio"><a href="javascript:" @click="onPublicOptionClick(1)">
                  <img
                    :src="require(`../../../assets/img/story_create_check_${publicOptions == 1 ? 'on' : 'off'}.png`)" />
                  {{ $t('photo.followers') }}
                </a></li>
              <li class="public_option_radio"><a href="javascript:" @click="onPublicOptionClick(2)">
                  <img
                    :src="require(`../../../assets/img/story_create_check_${publicOptions == 2 ? 'on' : 'off'}.png`)" />
                  {{ $t('photo.paid') }}
                </a></li>
            </ul>
            <div class="paid" v-if="publicOptions == 2">
              <div class="price_input">
                <input type="text" v-model="price"
                  style="width:100px;background-color: transparent;text-align: right;font-size: 18px;margin-right:5px;"
                  required />
                <img src="../../../assets/img/flowers.png" style="width:20px;height:20px;" />
              </div>
              <br />
              <span class="paid_hint"><a href="javascript:" @click="onPriceHint">{{ $t('photo.paid_hint') }}</a></span>
            </div>
          </div>
        </div>
        <div class="create_btn" @click="onDone">
          <a href="javascript:">{{ $t('common.done') }}</a>
        </div>
      </div>
    </div>
  </transition>

  <HashtagSelectPopup :show="showHashtagSelectPopup" @close="showHashtagSelectPopup = false"
    @selected="onHashtagSelected" />

  <PriceGuidelinePopup :show="showPriceGuidelinePopup" @close="showPriceGuidelinePopup = false" />
</template>

<style lang="scss" scoped src="./CreateMomentStep.scss"></style>

<script>
import { UPLOAD_MOMENT_IMAGE } from "../../../lib/constant";
import { requestPost, requestUploads, showToast } from "../../../lib/util";
import HashtagSelectPopup from './HashtagSelect.vue';
import PriceGuidelinePopup from './PriceGuidelinePopup.vue';
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination } from 'vue3-carousel'

export default {
  name: "CreateMomentStep2",
  components: {
    HashtagSelectPopup,
    PriceGuidelinePopup,
    Carousel,
    Slide,
    Pagination,
  },
  data() {
    return {
      myInfo: this.$store.getters.me,
      content: '',
      publicOptions: 0,
      price: 0,
      showHashtagSelectPopup: false,
      selectedHashtags: [],
      showPriceGuidelinePopup: false,
      backButtonRouteGuard: null,
    }
  },
  props: {
    show: { type: Boolean },
    mediaType: {
      type: Number,
      default: 0,
    },
    images: {
      type: Array,
      nullable: true,
    },
    imageDatas: {
      type: Array,
      nullable: true
    }
  },
  emits: ['close', 'done'],
  watch: {
    show(show) {
      if (show) {
        this.myInfo = this.$store.getters.me;
        this.content = '';
        this.publicOptions = 0;
        this.price = 0;
        this.selectedHashtags = [];

        document.addEventListener('keyup', this.onKeyPress);
        // back button
        this.backButtonRouteGuard = this.$router.beforeEach((to, from, next) => {
          this.$emit('close');
          next(false);
        });
      } else {
        // dialog hide
        document.removeEventListener('keyup', this.onKeyPress);
        // back button
        if (this.backButtonRouteGuard) {
          this.backButtonRouteGuard();
        }
      }
    }
  },
  methods: {
    onKeyPress: function (e) {
      if (e.keyCode == 27) {
        let me = document.querySelector('.pop_create').parentElement;
        let activeDlgList = document.querySelectorAll('.pop_back');

        if (activeDlgList.item(activeDlgList.length - 1) == me) {
          this.$emit('close');
        }
      }
    },
    onHashtagSelect: function () {
      this.showHashtagSelectPopup = true;
    },
    onHashtagSelected: function (hashtags) {
      this.selectedHashtags = hashtags;
      this.showHashtagSelectPopup = false;
    },
    onPublicOptionClick: function (index) {
      this.publicOptions = index;
    },
    onPriceHint: function () {
      this.showPriceGuidelinePopup = true;
    },
    onDone: async function () {
      if (this.content == '') {
        showToast(this.$t('msg.input_photo_content'));
        return;
      }

      if (this.publicOptions == 2) {
        if (this.price < 1) {
          showToast(this.$t('photo.price_must_be_nonzero'));
          return;
        }
      }

      var maxPrice = 0;
      if (this.mediaType == 0) {
        maxPrice = 20 * this.images.length;
      } else if (this.mediaType == 1) {
        maxPrice = 60;
      } else if (this.mediaType == 2) {
        maxPrice = 30;
      }

      if (this.price > maxPrice) {
        showToast(this.$t('photo.price_must_below', { maxPrice: maxPrice }));
        return;
      }

      if (this.mediaType == 0) {
        let response = await requestUploads(Array.from(this.images), {
          uid: this.myInfo.uid,
          category: UPLOAD_MOMENT_IMAGE
        });
        if (response) {
          let uploadedUrls = response.join('###');
          let _app = this;

          requestPost(
            'zzalbe/createZzalbe',
            {
              uid: this.myInfo.uid,
              title: '',
              content: this.content,
              image: uploadedUrls,
              media_type: this.mediaType,
              media_url: '',
              category: 0,
              public_flag: this.publicOptions,
              price: this.price,
              hash_tags: this.selectedHashtags.join(',')
            },
            response => {
              _app.myInfo.points = response.point;
              _app.$store.dispatch('setMyInfo', _app.myInfo);

              let toastMsg = this.$t('msg.zzalbe_registered');
              if (response.paid_points > 0) {
                toastMsg = this.$t('msg.zzalbe_registered_with_point', { point: response.paid_points });
              }

              showToast(toastMsg);
              _app.$emit('done');
            },
            (code, msg) => {
              showToast(msg);
            }
          );
        }
      }
    }
  }
}
</script>
