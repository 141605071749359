<template>
  <transition name="fade">
    <div v-if="show" class="pop_back" @click="$emit('close')">
      <div class="pop_moment" @click="$event.stopPropagation()">
        <div class="info">
          <div @click="onProfile(photoInfo)">
            <UserImage :gender="photoInfo.user_sex" :image="photoInfo.user_profile_image"/>
            <img  style="width: 18px; height: 15px; margin-top: -5px;" :src="require('../../assets/flag/' + photoInfo.user_country_code + '.png')">
            <b>{{ photoInfo.user_nickname }}</b>
            <span :class="[{male: photoInfo.user_sex == '0', female: photoInfo.user_sex == '1'}]">
              ({{
                photoInfo.user_age + ' ' +
                (photoInfo.user_sex == 1 ? $t('common.female') : $t('common.male'))
              }})
            </span>
          </div>
          <a href="javascript:" @click="$emit('close')">
            <img src="../../assets/img/btn_close.png">
          </a>
          <a href="javascript:" @click="onReport(photoInfo)">
            <img src="../../assets/img/ico_report.png" class="report">
          </a>
        </div>
        <div class="moment_contents">
          <div class="scroll">
            <div v-if="photoInfo.media_type === '0'">
              <carousel>
                <slide v-for="(image, index) in imageList" :key="index">
                  <img :src="image" class="upload-image" @click="onImage(image)" />
                </slide>
                
                <template #addons>
                  <pagination />
                </template>
              </carousel>
            </div>
            <div v-else-if="photoInfo.media_type === '1'">
              <video :src="photoInfo.media_url" controls loop muted autoplay>
                This video isn't supported by the browser.
              </video>
            </div>
            <div v-else-if="photoInfo.media_type === '2'">
              <img :src="photoInfo.image"/>
              <audio controls autoplay>
                <source :src="photoInfo.media_url">
                This audio isn't supported by the browser.
              </audio>
            </div>
          </div>
          
          <div class="story_item_public_flag_tag">
            <story-item-public-flag-tag-view :isMyMoment="myInfo != null && myInfo.uid == photoInfo.uid"
              :publicFlag="photoInfo.public_flag" :price="photoInfo.price" :isFollowing="photoInfo.is_following"
              :isPurchased="photoInfo.is_purchased" @onBuy="onBuy(photoIndex)" />
          </div>
        </div>

        <div class="moment_text_hashtags">
          {{ photoInfo.content }}
        </div>

        <div v-if="photoInfo.hashtags.length > 0" class="moment_text_hashtags">
          {{ photoInfo.hashtags
                  .split(',')
                  .map(it => `#${it}`)
                  .join(',') }}
        </div>
        
        <div class="moment_btn">
          <a href="javascript:" @click="onPrevPhoto">{{ $t('photo.prev_contents') }}</a>
          <a href="javascript:" @click="onNextPhoto">{{ $t('photo.next_contents') }}</a>
        </div>
      </div>
    </div>
  </transition>
  
  <StoryPurchaseConfirm
      :show="showStoryPurchaseConfirmModal"
      :photoInfo="photoInfo"
      @close="showStoryPurchaseConfirmModal = false"
      @ok="onStoryPurchaseOk"/>
</template>

<style lang="scss" scoped src="./MomentDetail.scss"></style>

<script>
import UserImage from "../../components/UserImage";
import { requestPost, showToast } from "@/lib/util";
import { EVENT_NAME } from "../../lib/constant";
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination } from 'vue3-carousel'
import StoryItemPublicFlagTagView from "../../components/StoryItemPublicFlagTagView.vue";
import StoryPurchaseConfirm from "@/views/moment/StoryPurchaseConfirm";


export default {
  name: 'MomentDetail',
  components: {
    UserImage,
    Carousel,
    Slide,
    Pagination,
    StoryPurchaseConfirm,
    StoryItemPublicFlagTagView,
  },
  props: ["data", "index", "show"],
  emits: ['close', 'delete', 'album'],
  data() {
    let myInfo = this.$store.getters.me;

    return {
      myInfo: myInfo,
      photoInfo: {},
      photoIndex: 0,
      isMyPhoto: 0,
      photoUsers: [],
      showReportModal: false,
      backButtonRouteGuard: null,
      showStoryPurchaseConfirmModal: false,
    }
  },
  computed: {
    imageList() {
      if (this.photoInfo.image) {
        return this.photoInfo.image.split('###').filter(it => it != '');
      } else {
        return [];
      }
    }
  },
  watch: {
    show(show) {
      if (show) {
        this.myInfo = this.$store.getters.me;
        this.photoInfo =  this.data[this.index];
        this.photoIndex =  this.index;
        this.isMyPhoto = (this.myInfo.uid == this.photoInfo.uid);
        this.photoUsers = [];
        document.addEventListener('keyup', this.onKeyPress);
        this.emitter.on(EVENT_NAME.REPORT_SUCCESS, this.onReportSuccess);
        // back button
        this.backButtonRouteGuard = this.$router.beforeEach((to, from, next) => {
          this.$emit('close');
          next(false);
        });
      } else {
        document.removeEventListener('keyup', this.onKeyPress);
        this.emitter.off(EVENT_NAME.REPORT_SUCCESS, this.onReportSuccess);
        // back button
        if (this.backButtonRouteGuard) {
          this.backButtonRouteGuard();
        }
      }
    }
  },
  methods: {
    onKeyPress: function (e) {
      if (e.keyCode == 27) {
        let me = document.querySelector('.photo-detail-content').offsetParent.offsetParent;
        let activeDlgList = document.querySelectorAll('.modal-wrapper');

        if (activeDlgList.item(activeDlgList.length - 1) == me) {
          this.$emit('close');
        }
      }
    },
    onVideo: function () {
      this.$root.showUserDlg(this.photoInfo);
    },
    onMessage: function () {
      this.$root.showMessageSendDlg(this.photoInfo);
    },
    onLike: function () {
      let user = this.photoInfo;
      let _app = this;
      requestPost(
        'user/likeUser',
        {
          uid: this.myInfo.uid,
          like_uid: user.uid
        },
        response => {
          showToast(_app.$t('message.msg_like_success'));
          _app.photoInfo.user_like_cnt = response.like_count;
        },
        (code, msg) => {
          showToast(msg);
        }
      );
    },
    onJjim: function () {
      let user = this.photoInfo;
      let _app = this;
      requestPost(
        'jjim/createJjim',
        {
          uid: this.myInfo.uid,
          peer_uid: user.uid
        },
        () => {
          showToast(_app.$t('video.video_profile_02', { nickname: user.user_nickname }));
        },
        (code, msg) => {
          showToast(msg);
        }
      );
    },

    onImage: function (image) {
      if (image != '') {
        window.open(image, '_blank');
      }
    },
    onReport: function (user) {
      this.$root.showReportDlg(user);
    },
    onProfile: function (user) {
      this.$root.showUserDlg(user);
    },
    onReportSuccess: function () {
      this.$emit('close');
    },
    onDelete: function () {
      let _app = this;
      this.$root.showConfirmDlg(
        '',
        this.$t('album.confirm'),
        this.$t('common.delete'),
        this.$t('common.cancel'),
        () => {
          requestPost(
            'zzalbe/deleteZzalbe',
            {
              uid: _app.myInfo.uid,
              zzalbe_id: _app.photoInfo.zzalbe_id
            },
            () => {
              showToast(_app.$t('photo.delete_success'));
              _app.$emit('delete');
            },
            (code, msg) => {
              showToast(msg);
            }
          );
        }
      );
    },
    onPrevPhoto: function () {
      this.photoIndex = this.photoIndex - 1;
      if (this.photoIndex < 0) {
        this.photoIndex = this.data.length - 1;
      }
      this.photoInfo = this.data[this.photoIndex];
    },
    onNextPhoto: function () {
      this.photoIndex = this.photoIndex + 1;
      if (this.photoIndex >= this.data.length) {
        this.photoIndex = 0;
      }
      this.photoInfo = this.data[this.photoIndex];
    },
    onBuy: function (idx) {
      this.photoIndex = idx;
      this.photoInfo = this.data[this.photoIndex];
      this.showStoryPurchaseConfirmModal = true;
    },
    onStoryPurchaseOk: function () {
      this.showStoryPurchaseConfirmModal = false;
      if (this.myInfo.gift_item2_count < this.photoInfo.price) {
        this.$root.showConfirmDlg(
          this.$t('story_purchase.item_not_enough'),
          this.$t('story_purchase.purchase_confirm'),
          this.$t('common.ok'),
          this.$t('common.cancel'),
          () => {
            this.$emit('close')
            this.$router.push('/gift-items');
          }
        );
      } else {
        let _app = this;

        requestPost(
          'zzalbe/purchaseStoryItem',
          {
            uid: this.myInfo.uid,
            zzalbe_id: this.photoInfo.zzalbe_id,
          },
          response => {
            _app.myInfo.gift_item1_count = response.gift_item1_count;
            _app.myInfo.gift_item2_count = response.gift_item2_count;
            _app.myInfo.gift_item3_count = response.gift_item3_count;
            _app.myInfo.gift_item4_count = response.gift_item4_count;
            _app.myInfo.gift_item5_count = response.gift_item5_count;
            _app.$store.dispatch('setMyInfo', _app.myInfo);
            
            showToast(this.$t('story_purchase.purchase_success'));

            _app.photoInfo.is_purchased = true;
            _app.data[_app.photoIndex] = _app.photoInfo;
          },
          (code, msg) => {
            showToast(msg);
          },
          false
        );
      }
    },
  },
}
</script>
