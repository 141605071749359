<template>
  <transition name="fade">
    <div v-if="show" class="pop_back" @click="$emit('close')">
      <div class="pop_hashtag_select" @click="$event.stopPropagation()">
        <a href="javascript:" @click="$emit('close')" class="close">
          <img src="../../../assets/img/btn_close.png">
        </a>
        <div class="title">{{ $t('photo.hashtag_select') }}</div>
        <div class="hint">
          {{ $t('photo.hashtag_select_hint') }}
        </div>
        <ul class="hashtag_list">
          <li v-for="hashtag in hashtags" class="hashtag_item"
            :class="{ selected: selectedHashtags.indexOf(hashtag) !== -1 }">
            <a href="javascript:" @click="onItemClick(hashtag)">#{{ hashtag }}</a>
          </li>
        </ul>
        <a href="javascript:" class="btn_not" @click="$emit('close')">
          {{ $t('common.cancel') }}
        </a>
        <a href="javascript:" class="btn_yes" @click="onDone">
          {{ $t('common.done') }}
        </a>
      </div>
    </div>
  </transition>
</template>

<script>
import { showToast } from "../../../lib/util";

export default {
  name: "HashtagSelect",
  props: {
    show: {
      type: Boolean,
      default: false
    },
  },
  emits: ['close', 'selected'],
  data() {
    return {
      hashtags: this.$store.getters.setting == null ? [] : this.$store.getters.setting.story_hash_tags.split(','),
      selectedHashtags: [],
      backButtonRouteGuard: null,
    }
  },
  watch: {
    show(show) {
      if (show) {
        // dialog show
        document.addEventListener('keyup', this.onKeyPress);
        // back button
        this.backButtonRouteGuard = this.$router.beforeEach((to, from, next) => {
          this.$emit('close');
          next(false);
        });
      } else {
        // dialog hide
        document.removeEventListener('keyup', this.onKeyPress);
        // back button
        if (this.backButtonRouteGuard) {
          this.backButtonRouteGuard();
        }
      }
    }
  },
  methods: {
    onKeyPress: function (e) {
      if (e.keyCode == 27) {
        let me = document.querySelector('.pop_hashtag_select').parentElement;
        let activeDlgList = document.querySelectorAll('.pop_back');

        if (activeDlgList.item(activeDlgList.length - 1) == me) {
          this.$emit('close');
        }
      }
    },
    onItemClick: function (item) {
      if (this.selectedHashtags.indexOf(item) >= 0) {
        this.selectedHashtags = this.selectedHashtags.filter(
          it => it != item,
        );
      } else {
        if (this.selectedHashtags.length == 3) {
          showToast(this.$t('photo.hashtag_select_hint'));
          return;
        }
        this.selectedHashtags = [
          ...this.selectedHashtags,
          item,
        ];
      }
    },
    onDone: function () {
      this.$emit('selected', this.selectedHashtags);
    }
  }
}
</script>

<style scoped>
.pop_hashtag_select {
  position: fixed;
  width: 300px;
  height: auto;
  left: 50%;
  top: 50%;
  margin-left: -190px;
  margin-top: -130px;
  padding: 20px;
  ;
  background-color: white;
  border-radius: 15px;
  text-align: center;
  z-index: 110;
}

.pop_hashtag_select .close {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10;
}

.pop_hashtag_select .close img {
  width: 20px;
  height: 20px;
  border-radius: 0;
  opacity: 0.5;
}

.pop_hashtag_select .title {
  font-size: 17px;
  font-weight: 600;
  padding: 10px 10px 0px 10px;
}

.pop_hashtag_select .hint {
  position: relative;
  padding: 15px;
  text-align: center
}

.pop_hashtag_select .hashtag_list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 5px;
  margin-bottom: 20px;
}

.pop_hashtag_select .hashtag_list .hashtag_item {
  margin-right: 10px;
  margin-bottom: 10px;
  background-color: #999;
  border-radius: 10px;
}

.pop_hashtag_select .hashtag_list .hashtag_item.selected {
  background-color: #e24c4d;
  border-radius: 10px;
}

.pop_hashtag_select .hashtag_list .hashtag_item a {
  padding: 5px 8px;
  font-size: 14px;
  color: #fff;
}

.pop_hashtag_select .btn_yes {
  font-size: 14px;
  font-weight: 700;
  background-color: #e24c4d;
  color: white;
  border-radius: 10px;
  padding: 7px 25px;
  text-align: center;
  margin: 0 3px;
}

.pop_hashtag_select .btn_not {
  font-size: 14px;
  font-weight: 700;
  background-color: #999;
  color: white;
  border-radius: 10px;
  padding: 7px 25px;
  text-align: center;
  margin: 0 3px;
}

@media only screen and (max-width:766px) {
  .pop_hashtag_select {
    width: calc(100% - 20px);
    left: 0;
    bottom: 0;
    top: auto;
    margin-left: 0;
    margin-top: 0;
    padding: 10px;
    border-radius: 15px;
    z-index: 101;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}
</style>